import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Grid,
  InputAdornment,
  Button,
  Tooltip,
  TextField
} from '@material-ui/core';

import illustration1 from '../../../../assets/images/illustrations/pack4/404.svg';
import SearchTwoToneIcon from '@material-ui/icons/SearchTwoTone';
import SocialIcons from 'sites/shipper/components/SocialIcons/SocialIcons'

const ReferralError = ({ content }) => {
  return (
    <>
      <div className="app-wrapper bg-white">
        <div className="app-main">
          <div className="app-content p-0">
            <div className="app-inner-content-layout--main">
              <div className="flex-grow-1 w-100 d-flex align-items-center">
                <div className="bg-composed-wrapper--content">
                  <div className="hero-wrapper bg-composed-wrapper min-vh-100">
                    <div className="flex-grow-1 w-100 d-flex align-items-center">
                      <Grid
                        item
                        lg={6}
                        md={9}
                        className="px-4 px-lg-0 mx-auto text-center text-black">
                        <img
                          src={illustration1}
                          className="w-50 mx-auto d-block my-5 img-fluid"
                          alt="..."
                        />

                        <h3 className="font-size-xxl line-height-sm font-weight-light d-block px-3 mb-3 text-black-50">
                          Invitation is invalid or has expired.
                        </h3>
                        <p>{content.msg}</p>
                        <Grid justify="center" container spacing={0}>
                          <Grid
                            item
                            sm={12}
                            md={4}
                            className="mt-4 mt-lg-3 px-2">
                            <Button
                              href="/register"
                              className="d-block w-100 btn-first"
                              size="large">
                              <span className="btn-wrapper--label">
                                Go to Shipper Registration
                              </span>
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </div>
                    <div className="hero-footer py-4">
                      <SocialIcons />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReferralError;
