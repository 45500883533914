import React from 'react';
import PropTypes from 'prop-types';

import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
// import { styles } from './StyledInput.css';

const styles = (theme) => ({
  //   textfield: {
  //     display: 'flex',
  //     margin: 8,
  //     backgroundColor: '#f2f2f2',
  //     borderRadius: '4px 4px 0px 0px'
  //   },
  //   label: {
  //     margin: '8px 0px 0px 16px',
  //     color: theme.palette.darkaccent,
  //     fontSize: 12
  //   },
  //   input: {
  //     display: 'flex',
  //     alignItems: 'center',
  //     paddingLeft: 15,
  //     fontSize: 14,
  //     height: 36
  //   },
  //   inputMultiline: {
  //     display: 'flex',
  //     alignItems: 'center',
  //     paddingLeft: 15,
  //     fontSize: 14
  //   },
  //   formControl: {
  //     display: 'flex',
  //     backgroundColor: '#eceff1',
  //     opacity: 0.3,
  //     borderRadius: 50
  //   },
  //   required: {
  //     color: 'red'
  //   }
});

const StyledInput = (props) => {
  const {
    classes,
    size,
    name,
    label,
    type,
    className,
    rows,
    rowsMax,
    multiline,
    disabled,
    autoFocus,
    value,
    required,
    placeholder,
    onChange,
    onBlur,
    error,
    helperText,
    variant,
    margin,
    fullWidth,
    autoComplete,
    InputProps,
    characterLimit,
    ...rest
  } = props;

  //   const inputProps = multiline
  //     ? {
  //         classes: {
  //           root: classes.inputMultiline
  //         },
  //         step: 300
  //       }
  //     : {
  //         classes: {
  //           root: classes.input
  //         },
  //         step: 300
  //       };

  return (
    <div className='d-flex flex-column c-gap-half'>
      <TextField
        {...rest}
        size={size}
        required={required}
        autoFocus={autoFocus}
        name={name}
        id={name}
        autoComplete={autoComplete}
        label={label}
        type={type}
        rows={rows}
        rowsMax={rowsMax}
        multiline={multiline}
        disabled={disabled}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        placeholder={placeholder}
        onWheel={(e)=> e.target.blur()}
        variant={variant}
        margin={margin}
        fullWidth={fullWidth}
        helperText={helperText}
        error={error}
        InputProps={{
          ...InputProps
          // classes: {
          //   root: clsx({ 'font-size-xs': size === 'small' })
          // }
        }}
        inputProps={{
          maxLength: multiline ? 50000 : characterLimit
        }}
      />
      {!helperText && error && <span className='font-size-xs text-danger'>{error}</span>}
    </div>
  );
};

StyledInput.defaultProps = {
  size: 'small',
  variant: 'outlined',
  margin: 'normal',
  fullWidth: true,
  characterLimit: 100
};

StyledInput.propTypes = {
  /**
   * Override or extend the styles applied to the component.
   */
  className: PropTypes.object,
  /**
   * The key of the input field.
   */
  name: PropTypes.string.isRequired,
  /**
   * The label of the input input.
   */
  label: PropTypes.string,
  /**
   * The type of input field.
   */
  type: PropTypes.string,
  /**
   * The value of the input field.
   */
  value: PropTypes.any,
  /**
   * Func that handles on change events.
   */
  onChange: PropTypes.func.isRequired,
  /**
   * Defines the number of rows of the input field.
   */
  rows: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * Defines the max number of row of the input field.
   */
  rowsMax: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * If true, the component will enable multi-line inputs.
   */
  multiline: PropTypes.bool,
  /**
   * If true, the input field will be disabled.
   */
  disabled: PropTypes.bool,
  /**
   * If true, the input field will be in focus.
   */
  autoFocus: PropTypes.bool,
  /**
   * If true, the input field is highlighted in red.
   */
  required: PropTypes.bool,
  /**
   * onBlur callBack
   */
  onBlur: PropTypes.func,
  /**
   * If true, the input field is highlighted in red.
   */
  error: PropTypes.bool,
  helperText: PropTypes.string,
  variant: PropTypes.string,
  margin: PropTypes.string,
  size: PropTypes.string,
  fullWidth: PropTypes.bool,
  characterLimit: PropTypes.number,

};

export default withStyles(styles)(StyledInput);
