import React, { lazy, Suspense, useState, useEffect } from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import { ClimbingBoxLoader } from 'react-spinners';

import { ThemeProvider } from '@material-ui/styles';

import MuiTheme from './theme';

// Layout Blueprints

import { MinimalLayout, PresentationLayout } from './layout-blueprints';

// Error Pages

import PageError404 from './example-pages/PageError404';
import PageError500 from './example-pages/PageError500';
import PageError505 from './example-pages/PageError505';
import ShipperRoute from 'sites/shipper/components/ShipperRoute';
import PageError403 from 'components/PageError403';

import EnterEmail from 'sites/shipper/pages/Forgot Password/EnterEmail/EnterEmail';
import EnterPassword from 'sites/shipper/pages/Forgot Password/EnterPassword/EnterPassword';
import AcceptInvitaion from './sites/shipper/pages/AcceptInvitaion/AcceptInvitaion';
import DocumentPreviewPage from 'sites/shipper/pages/Documents/DocumentPreviewPage/DocumentPreviewPage';
import Referral from 'sites/shipper/pages/Register/Referral/Referral';
import GetAQuote from 'sites/shipper/pages/Website/GetAQuote/GetAQuote';
import AllShipmentsLead from 'sites/shipper/pages/Website/AllShipmentsLead/AllShipmentsLead';
import ShipmentTracking from 'sites/shipper/pages/Website/ShipmentTracking/ShipmentTracking';
import TeamMemberRegistartion from 'sites/shipper/pages/Register/TeamMemberRegistration/teamMemberRegistartion';

// Lazy Load Actual Pages

// Shipper Pages
const ShipperRegister = lazy(() => import('./sites/shipper/pages/Register'));
const ShipperRegisterEmailVerify = lazy(() =>
  import('./sites/shipper/pages/Register/EmailVerify/EmailVerify')
);
const ShipperRegisterReferral = lazy(() =>
  import('./sites/shipper/pages/RegisterReferral')
);
const ShipperLogin = lazy(() => import('./sites/shipper/pages/Login'));
const ShipperLogout = lazy(() => import('./sites/shipper/pages/Logout'));
const ShipperRecover = lazy(() =>
  import('./sites/shipper/pages/RecoverPassword')
);
const ShipperResetPassword = lazy(() =>
  import('./sites/shipper/pages/ResetPassword')
);
const ShipperHome = lazy(() => import('./sites/shipper/pages/Home'));
const CreateCollaboratorPassword = lazy(() =>
  import('./sites/shipper/pages/Collaborators/CreatePassword/CreatePassword')
);

const Routes = () => {
  const location = useLocation();
  console.log('Inside Main routes', location);

  const pageVariants = {
    initial: {
      opacity: 0
    },
    in: {
      opacity: 1
    },
    out: {
      opacity: 0
    }
  };

  const pageTransition = {
    type: 'tween',
    ease: 'linear',
    duration: 0.3
  };

  const SuspenseLoading = () => {
    const [show, setShow] = useState(false);
    useEffect(() => {
      let timeout = setTimeout(() => setShow(true), 300);
      return () => {
        clearTimeout(timeout);
      };
    }, []);

    return (
      <>
        <AnimatePresence>
          {show && (
            <motion.div
              key="loading"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.4 }}>
              <div className="d-flex align-items-center flex-column vh-100 justify-content-center text-center py-3">
                <div className="d-flex align-items-center flex-column px-4">
                  <ClimbingBoxLoader color={'#3c44b1'} loading={true} />
                </div>
                <div className="text-muted font-size-xl text-center pt-3">
                  Please wait while we load the data
                  {/* <span className="font-size-lg d-block text-dark">
                    This live preview instance can be slower than a real
                    production build!
                  </span> */}
                </div>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </>
    );
  };
  return (
    <ThemeProvider theme={MuiTheme}>
      <AnimatePresence>
        <Suspense fallback={<SuspenseLoading />}>
          <Switch>
            {/* preview for document and images  */}
            <Route path="/preview" component={DocumentPreviewPage} />

            {/* main routes  */}
            <Redirect exact from="/" to="/dashboard" />

            <Route
              path={[
                '/login',
                '/logout',
                '/register',
                '/team-member-registration/:token',
                // '/register/:referralCode',
                // '/recover',
                // '/reset/:token',
                '/forgot-password',
                '/forgot-password-password/:id',
                '/forwarder-my-shipper-invite/:forwarderProfileId/:shipperProfileId',
                '/referral/:referralCode',
                // '/app/forwarder/login',
                // '/app/forwarder/logout',
                // '/app/forwarder/register',
                // '/app/forwarder/recover',
                // '/app/forwarder/reset/:token',
                '/error403',
                '/error404',
                '/error500',
                '/error505',
                '/website/get-a-quote',
                '/website/shipment-tracking',
                '/website/all-shipment-lead',
                '/collaborator/password/:collaboratorId'
              ]}>
              <MinimalLayout>
                <Switch location={location} key={location.pathname}>
                  <motion.div
                    initial="initial"
                    animate="in"
                    exit="out"
                    variants={pageVariants}
                    transition={pageTransition}>
                    <Route path="/login" component={ShipperLogin} />
                    <Route path="/logout" component={ShipperLogout} />
                    <Route
                      exact
                      path="/register/:referralCode"
                      component={ShipperRegisterReferral}
                    />

                    <Route
                      exact
                      path="/referral/:referralCode"
                      component={Referral}
                    />
                    <Route exact path="/register" component={ShipperRegister} />
                    <Route
                      exact
                      path="/team-member-registration/:token"
                      component={TeamMemberRegistartion}
                    />
                    <Route
                      exact
                      path="/forgot-password"
                      component={EnterEmail}
                    />
                    <Route
                      exact
                      path="/forgot-password-password/:id"
                      component={EnterPassword}
                    />
                    <Route
                      path="/forwarder-my-shipper-invite/:forwarderProfileId/:shipperProfileId"
                      component={AcceptInvitaion}
                    />

                    {/* <Route path="/recover" component={ShipperRecover} />
                    <Route
                      path="/reset/:token"
                      component={ShipperResetPassword}
                    />

                    <Route
                      path="/app/forwarder/recover"
                      component={ForwarderRecover}
                    /> */}
                    <Route path="/error403" component={PageError403} />
                    <Route path="/error404" component={PageError404} />
                    <Route path="/error500" component={PageError500} />
                    <Route path="/error505" component={PageError505} />

                    {/* Website Routes */}
                    <Route path="/website/get-a-quote" component={GetAQuote} />
                    <Route
                      path="/website/shipment-tracking"
                      component={ShipmentTracking}
                    />
                    <Route
                      path="/website/all-shipment-lead"
                      component={AllShipmentsLead}
                    />

                    <Route
                      exact
                      path="/collaborator/password/:collaboratorId"
                      component={CreateCollaboratorPassword}
                    />
                  </motion.div>
                </Switch>
              </MinimalLayout>
            </Route>

            {/* routes created by rajkumar jaiswal */}
            <Route
              exact
              path="/email/shipper/register/:registrationTokenId"
              component={ShipperRegisterEmailVerify}
            />
            {/* <Route path="/app/forwarder/logout" component={ShipperLogin} /> */}

            {/* <ShipperRoute
              path={['/m/mailbox/message']}
              hideNav
              hideHeader
              component={ShipperHome}
            /> */}
            <ShipperRoute
              path={['/mobi-chat/id/shipment-details']}
              hideNav
              hideHeader
              component={ShipperHome}
            />
            {/* <ShipperRoute
              path={['/m/mailbox']}
              hideNav
              hideHeader
              component={ShipperHome}
            /> */}

            <ShipperRoute
              path={['/shipment/pay-due-amount/:id']}
              hideNav
              component={ShipperHome}
            />

            <ShipperRoute
              path={['/checkout/:type/:id']}
              hideNav
              component={ShipperHome}
            />

            <ShipperRoute
              path={['/upload-kyc-documents']}
              hideNav
              component={ShipperHome}
            />

            <ShipperRoute
              path={['/update-profile-meta']}
              hideNav
              component={ShipperHome}
            />


            <ShipperRoute
              path={['/complete-profile']}
              hideNav
              component={ShipperHome}
            />


            <ShipperRoute path={['/freight']} hideNav component={ShipperHome} />
            <ShipperRoute
              path={['/shipment-detail/:shipment_id']}
              hideNav
              component={ShipperHome}
            />
            {/* <ShipperRoute
              path={['/collaborator-shipments']}
              hideNav
              component={ShipperHome}
            /> */}
            <ShipperRoute
              path={['/collaborator/shipment-detail/:shipment_id']}
              hideNav
              component={ShipperHome}
            />
            <ShipperRoute
              path={['/payment-success/:type/:id']}
              hideNav
              component={ShipperHome}
            />

            <ShipperRoute path={['/quotes/:id']} component={ShipperHome} />

            <ShipperRoute path={['/dashboard', '/']} component={ShipperHome} />

            <Route
              render={() => {
                console.log('No such route');
                return <Redirect to={'/error404'} />;
              }}
            />
          </Switch>
        </Suspense>
      </AnimatePresence>
    </ThemeProvider>
  );
};

export default Routes;
